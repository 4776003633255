import {yupResolver} from '@hookform/resolvers/yup';
import React, {useState} from 'react';
import {Badge, Button, Col, Form, Row} from 'react-bootstrap';
import {PencilFill, Trash} from 'react-bootstrap-icons';
import {useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import {IInvoiceData} from '../app/form/InvoiceForm';
import {calculateDiscount, calculateTax, formatPrice} from '../functions/formatPrice';
import {toastSlice} from '../store/slices/toasts.slice';


export const productList = [
    {
        id: 1,
        name: 'Estrich E300 || 6-7cm',
        quantity: '1',
        unit: 'm2',
        price: 19
    },
    {
        id: 2,
        name: 'Estrich E250 || 6-7cm',
        quantity: '1',
        unit: 'm2',
        price: 17
    },
    {
        id: 3,
        name: 'Schnellestrich Retanol || 5-7 Tage',
        quantity: '1',
        unit: 'm2',
        price: 8
    },
    {
        id: 4,
        name: 'Schnellestrich Retanol || 14 Tage',
        quantity: '1',
        unit: 'm2',
        price: 6
    },
    {
        id: 5,
        name: 'Schnellestrich Retanol || 21 Tage',
        quantity: '1',
        unit: 'm2',
        price: 4
    },

    {
        id: 6,
        name: 'Kleine Flächen || 5m2- 20m2 || 6-8cm',
        quantity: '1',
        unit: 'm2',
        price: 1000
    },
    {
        id: 7,
        name: 'Kleine Flächen || 21m2- 50m2 || 6-8cm',
        quantity: '1',
        unit: 'm2',
        price: 1300
    },
    {
        id: 8,
        name: 'Kleine Flächen || 51m2- 100m2 || 6-8cm',
        quantity: '1',
        unit: 'm2',
        price: 1600
    },
    {
        id: 9,
        name: 'Styroporbeton ||  Unter 100m2 || 3-7cm',
        quantity: '1',
        unit: 'm3',
        price: 1000
    }, {
        id: 10,
        name: 'Styroporbeton || Ab 20m3',
        quantity: '1',
        unit: 'm3',
        price: 100
    }, {
        id: 11,
        name: 'Styroporbeton || Unter 15m3',
        quantity: '1',
        unit: 'm3',
        price: 1000
    },
]

export interface IProduct {
    name: string;
    quantity: string;
    unit: string;
    price: number;
    createdAt?: number;
}

const productSchema = yup.object({
    name: yup.string().required(),
    quantity: yup.string().required(),
    unit: yup.string().required(),
    price: yup.number().min(0.1).typeError('price mora biti broj, koristiti tacku za decimalu, a ne zarez (ukoliko se pise decimala)').required(),
});

interface IProps {
    watch: UseFormWatch<IInvoiceData>
    setValue: UseFormSetValue<IInvoiceData>
}

const InvoiceTable: React.FC<IProps> = ({setValue, watch}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [formKey, setFormKey] = useState(Math.random());
    const [selectedExisting, setSelectedExisting] = useState<number | null>(null)
    const [isPredefinedOpen, setIsPredefinedOpen] = useState(false)
    const [isFormOpened, setIsFormOpened] = useState(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        watch: createWatch
    } = useForm<IProduct>({
        resolver: yupResolver(productSchema),
        mode: 'onSubmit'
    });

    const totalSum = (watch('products') || [])?.reduce((curr, acc) => (acc?.price * Number(acc?.quantity)) + curr, 0);

    const createProductHandler = (formData: IProduct) => {
        const isUpdate = !!formData?.createdAt;
        const currentProducts = watch('products') || [];
        if (isUpdate) {
            setValue('products', (currentProducts || []).map(product => {
                if (product.createdAt === formData?.createdAt) {
                    return formData
                }
                return product;
            }));
        } else {
            setValue('products', (currentProducts || []).concat({
                ...formData,
                createdAt: new Date().getTime()
            }));
        }
        setIsFormOpened(false);
        setFormKey(Math.random());
        reset({});
    };

    const handleDeleteProduct = (productId: number) => {
        const currentProducts = watch('products') || [];
        setValue('products', currentProducts.filter(product => product?.createdAt !== productId))
    }
    const handleUpdateProduct = (productId: number) => {
        const productSelected = watch('products')?.find(product => product.createdAt === productId);
        setSelectedExisting(null);
        setIsPredefinedOpen(false);
        setIsFormOpened(true);
        reset(productSelected);
    }
    const isEditable = !['paid', 'declined'].includes(watch('status') || '')
    const handleAddExisting = (idToAdd: number) => {
        if (!!idToAdd) {
            const productSelected = productList.find(product => product.id === idToAdd);
            setSelectedExisting(null);
            setIsPredefinedOpen(false);
            setIsFormOpened(true);
            reset(productSelected);
        }
    }
    return (
        <div className="border-top py-3">
            <Row className="gy-3">
                <Col md={3}>
                    <span className="fw-bold">
                   {t('general.nameOfService')}
                    </span>
                </Col>
                <Col md={2}>
                    <div className="fw-bold text-center">
                        {t('general.quantity')}
                    </div>
                </Col>
                <Col md={2}>
                    <div className="fw-bold text-center">
                        {t('general.unitOfMeasure')}
                    </div>
                </Col>
                <Col md={isEditable ? 2 : 3}>
                    <div className="fw-bold text-center">
                        {t('general.price')}
                    </div>
                </Col>
                <Col md={2}>
                    <div className="fw-bold text-center">
                        {t('general.total')}
                    </div>
                </Col>
                {isEditable && <Col md={1}>
                    <div className="fw-bold text-center">

                        {t('general.actions')}
                    </div>
                </Col>}
                <Col md={12} className="mt-0">
                    <hr/>
                </Col>
            </Row>
            {!!watch('products')?.length ? watch('products')?.map(product => {
                const total = Number(product.price) * Number(product.quantity)
                return (
                    <Row key={product?.createdAt}>
                        <Col md={3}>
                            <div>{product?.name}</div>
                        </Col>
                        <Col md={2}>
                            <div className="text-center">
                                {product?.quantity}
                            </div>
                        </Col>
                        <Col md={2}>
                            <div className="text-center">{product?.unit}</div>
                        </Col>
                        <Col md={isEditable ? 2 : 3}>
                            <div className="text-center">{formatPrice(product?.price)}</div>
                        </Col>
                        <Col md={2}>
                            <div className="text-center">{formatPrice(total)}</div>
                        </Col>
                        {isEditable && <Col md={1} className="text-center d-flex align-items-center">
                            <Badge bg="danger" onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                if (confirm(`${t('general.areYouSure')} ${product.name}`)) {
                                    handleDeleteProduct(product?.createdAt || 0)
                                }
                            }}
                                   className="cursor-pointer">
                                <Trash size={15}/>
                            </Badge>
                            <Badge
                                bg="warning" onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                handleUpdateProduct(product?.createdAt || 0)
                            }}
                                className="cursor-pointer ms-1">
                                <PencilFill size={15}/>
                            </Badge>
                        </Col>}

                        <Col md={12} className="mt-0">
                            <hr/>
                        </Col>
                    </Row>
                )
            }) : <h5 className="text-center">{t('general.listIsEmpty')}</h5>}
            <Form className="is-alter"
                  key={formKey}
                  id="create-form"
                  onSubmit={handleSubmit(createProductHandler)}
            >

                {isFormOpened && <Row>
                    <Col md={5}>
                        <Form.Control type="text"
                                      {...register('name')}
                                      as="textarea" rows={3}
                                      isInvalid={!!errors?.name}
                                      className="w-100 my-1" placeholder="Naziv usluge..."/>
                        {errors?.name && <div className="invalid-feedback">
                            {errors.name?.message}
                        </div>}
                    </Col>
                    <Col md={2}>
                        <Form.Control type="text"
                                      {...register('quantity')}
                                      isInvalid={!!errors?.quantity}
                                      className="w-100 my-1" placeholder="Kolicina..."/>
                        {errors?.quantity && <div className="invalid-feedback">
                            {errors.quantity?.message}
                        </div>}
                    </Col>
                    <Col md={2}>
                        <Form.Control type="text"
                                      {...register('unit')}
                                      isInvalid={!!errors?.unit}
                                      className="w-100 my-1" placeholder="Jedinica usluge..."/>
                        {errors?.unit && <div className="invalid-feedback">
                            {errors.unit?.message}
                        </div>}
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            {...register('price')}
                            isInvalid={!!errors?.price}
                            className="w-100 my-1"
                            placeholder="Cijena usluge..."/>
                        {errors?.price && <div className="invalid-feedback">
                            {errors.price?.message}
                        </div>}
                    </Col>
                </Row>}

                {isPredefinedOpen && <div className="d-flex align-items-center">
                    <select className="form-select"
                            onChange={(e) => {
                                setSelectedExisting(Number(e.target.value))
                            }}>
                        <option hidden>
                            select option
                        </option>
                        {productList?.map(product => {
                            return (
                                <option value={product.id} key={product.id}>
                                    {product.name}
                                </option>
                            )
                        })}

                    </select>
                    <Button variant="outline-primary" className="ms-5"
                            onClick={() => {
                                setIsPredefinedOpen(false);
                            }}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="info" className="text-white ms-1"
                            onClick={() => {
                                if (Number(selectedExisting)) {
                                    handleAddExisting(Number(selectedExisting))
                                } else {
                                    dispatch(toastSlice.actions.setToast({
                                        type: 'danger',
                                        message: 'Morate izabrati neki proizvod da bi dodali'
                                    }))
                                }
                            }}>
                        {t('general.add')}
                    </Button>

                </div>}
                {isFormOpened && <Row className="mt-3">
                    <Col md={12} className="d-flex justify-content-end">

                        <Button variant="outline-primary"
                                className="me-2"
                                onClick={() => {
                                    setIsFormOpened(false);
                                    setIsPredefinedOpen(false);
                                }}
                                type="button">{t('general.cancel')}</Button>
                        <Button variant="success"
                                name="create-form"
                                type="submit">{t(!!createWatch('createdAt') ? 'general.updateProduct' : 'general.createProduct')}</Button>
                    </Col>
                </Row>}
            </Form>
            <Row className="mt-3">
                {((!isFormOpened && !isPredefinedOpen) && isEditable) &&
                    <Col md={12} className="d-flex justify-content-end">
                        <Button variant="primary"
                                onClick={() => setIsFormOpened(true)}>
                            {t('general.addProduct')}
                        </Button>
                        <Button variant="info" className="text-white ms-2"
                                onClick={() => setIsPredefinedOpen(true)}>
                            {t('general.addPredefinedProduct')}
                        </Button>
                    </Col>}
                <Col md={12}>
                    <hr/>
                </Col>
                <Col md={6}/>
                <Col md={6} className="justify-content-end d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between my-1">
                        <span className="fw-bold">
                            {t('general.sumOfEverything')}:
                        </span>
                        <span>
                            {formatPrice(totalSum || 0)}
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-1 mb-2">
                        <span className="fw-bold">
                            {t('general.discount')}:
                        </span>
                        <span>
                           ({watch('discount')}%) {formatPrice(calculateDiscount(totalSum, +watch('discount')))}
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-1">
                        <span className="fw-bold">
                            {t('general.advancePayment')}:
                        </span>
                        <span>
                            {formatPrice(+watch('advancePayment'))}
                        </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-1">
                        <span className="fw-bold">
                            {t('general.tax')}:
                        </span>
                        <span>
                           ({watch('tax')}%) {formatPrice(calculateTax(totalSum, +watch('tax')))}
                        </span>
                    </div>

                    <div className="border-top pt-3 d-flex align-items-center justify-content-between">
                        <h5 className="fw-bold text-uppercase">
                            {t('general.total')}:
                        </h5>
                        <h5 className="fw-bold">
                            {formatPrice((totalSum + calculateTax(totalSum, +watch('tax')) - calculateDiscount(totalSum, +watch('discount'))) - watch('advancePayment'))}
                        </h5>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default InvoiceTable;
