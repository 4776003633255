import {yupResolver} from '@hookform/resolvers/yup';
import {doc, getDoc, updateDoc} from 'firebase/firestore';
import React, {useState} from 'react';
import {Button, Card, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import {ArrowLeft} from 'react-bootstrap-icons';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import * as yup from 'yup';
import InvoiceCard from '../../components/InvoiceCard';
import InvoiceTable, {IProduct} from '../../components/InvoiceTable';
import ModalBody from '../../components/ModalBody';
import StickyCard from '../../components/StickyCard';
import {db} from '../../firebase';
import {toastSlice} from '../../store/slices/toasts.slice';


export interface IInvoiceData {
    nbOfInvoice: string;
    uid?: string;
    projectPlace: string;
    id?: string;
    comment?: string;
    textRabat?: string;
    currentDate?: string;
    status?: string;
    payUntil: string;
    dateOfWork: string;
    discount: number;
    advancePayment: number;
    tax: number;
    invoiceFrom: ICompany;
    invoiceTo: ICompany;
    products?: IProduct[]
}

interface ICompany {
    email: string;
    name: string;
    address: string;
}

const companySchema = yup.object({
    email: yup.string().email().required(),
    name: yup.string().required(),
    address: yup.string().required(),
});


const schema = yup.object({
    projectPlace: yup.string().required(),
    dateOfWork: yup.string().required(),
    payUntil: yup.string().required(),
    nbOfInvoice: yup.string().required(),
    tax: yup.number().min(0).max(100).typeError('Must be a at least 0').required(),
    discount: yup.number().min(0).max(100).typeError('Must be a at least 0').required(),
    advancePayment: yup.number().min(0).typeError('Must be a at least 0').required(),
    invoiceFrom: companySchema,
    invoiceTo: companySchema,
});

interface IProps {
    isUpdate?: boolean;
}

const InvoiceForm: React.FC<IProps> = ({isUpdate = false}) => {
    const navigation = useNavigate();
    const [show, setShow] = useState(false)
    const dispatch = useDispatch();
    const {id} = useParams();
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: {errors},
    } = useForm<IInvoiceData>({
        resolver: yupResolver(schema),
        defaultValues: async () => {
            if (isUpdate && !!id) {
                const invoiceData = await getInvoiceById(id);
                return invoiceData ? {...invoiceData, advancePayment: invoiceData?.advancePayment || 0} : {
                    tax: 20,
                    discount: 0,
                    advancePayment: 0,
                    textRabat:
                        'Aufgrund § 19 Abs. 1a UStG 1994 geht die Umsatzsteuerschuld auf den Leistungsempfänger über \n' +
                        'UID Nr.:',
                    comment: ' Zahlungsbedingungen prompt nach Rechnungserhalt. Es gelten unsere allgemeinen Geschäftsbedingungen. '
                };
            } else {
                return {
                    tax: 20,
                    advancePayment: 0,
                    discount: 0,
                    textRabat:
                        'Aufgrund § 19 Abs. 1a UStG 1994 geht die Umsatzsteuerschuld auf den Leistungsempfänger über \n' +
                        'UID Nr.:',
                    comment: ' Zahlungsbedingungen prompt nach Rechnungserhalt. Es gelten unsere allgemeinen Geschäftsbedingungen. '

                };
            }
        }
    });
    const handleClose = () => {
        setShow(false);
    }
    const onFormSubmit = (formData: IInvoiceData) => {
        setShow(true);
    }

    const getInvoiceById = async (invoiceId: string) => {
        if (!invoiceId) {
            return;
        }
        try {
            // Reference to the document with the specified ID
            const invoiceRef = doc(db, 'invoices', invoiceId);

            // Get the document snapshot
            const invoiceSnapshot = await getDoc(invoiceRef);

            // Check if the document exists
            if (invoiceSnapshot.exists()) {
                // Extract the data from the document snapshot
                return invoiceSnapshot.data().invoices;
            } else {
                navigation(-1);
                dispatch(toastSlice.actions.setToast({
                    type: 'danger',
                    message: `Not found invoice with ID ${invoiceId}`
                }))
                return null; // or handle accordingly
            }
        } catch (error) {
            console.error('Error getting document:', error);
            return null; // or handle accordingly
        }
    }

    const handleUpdateStatus = async (newStatus: 'paid' | 'declined', invoiceId: string) => {
        try {
            // Reference to the document with the specified ID
            const invoiceRef = doc(db, 'invoices', invoiceId);
            // Update the document with the new data
            await updateDoc(invoiceRef, {invoices: {...getValues(), status: newStatus}});
            dispatch(toastSlice.actions.setToast({
                type: 'success',
                message: 'Invoice successfully updated!'
            }))
            navigation(-1);
        } catch (error) {
            dispatch(toastSlice.actions.setToast({
                type: 'danger',
                message: `Error updating document:', ${error}`
            }))
        }
    }

    const handleMarkAsPaid = async (invoiceId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(t('general.areYouSureMarkAsPaid', {invoiceId}))) {
            await handleUpdateStatus('paid', invoiceId)
        }
    }
    const handleMarkAsDeclined = async (invoiceId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(t('general.areYouSureMarkAsDeclined', {invoiceId}))) {
            await handleUpdateStatus('declined', invoiceId)
        }
    }
    return (
        <>
            <div className="my-5">
                <Form className="is-alter" id="global-form" onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="container">
                        <Row className="gy-2">
                            <Col xs={isUpdate ? 5 : 12}>
                                <Button variant="outline-primary" onClick={() => navigation(-1)}>
                                    <ArrowLeft/> {' '}
                                    {t('general.back')}
                                </Button>
                            </Col>
                            {isUpdate && <Col xs={3}>
                                <h5 className="mb-0">
                                    Status: {t(`general.${watch('status')}`)}
                                </h5>
                            </Col>}
                            {(isUpdate && !!id) && <Col xs={4} className="d-flex justify-content-end">
                                {watch('status') === 'issued' && <>
                                    <Button variant="success" size="sm" onClick={() => handleMarkAsPaid(id)}>
                                        {t('general.markAsPaid')}
                                    </Button>
                                    <Button variant="danger" size="sm" className="ms-2"
                                            onClick={() => handleMarkAsDeclined(id)}>
                                        {t('general.markAsDeclined')}
                                    </Button>
                                </>}
                            </Col>}
                            <Col md={9}>
                                <InvoiceCard register={register} errors={errors}/>
                            </Col>
                            <Col md={3}>
                                <StickyCard register={register} errors={errors}/>
                            </Col>
                        </Row>
                    </div>
                </Form>

                <Container>
                    <Row className="mt-4">
                        <Col md={9}>
                            <Card>
                                <Card.Body className="p-md-5">
                                    <InvoiceTable setValue={setValue} watch={watch}/>
                                    <Form.Control type="text"
                                                  as="textarea" rows={3}
                                                  {...register('comment')}
                                                  className="w-100 my-1"
                                                  placeholder="Dodatni komentar (Nije obavezno)"/>
                                    {/**/}
                                    {Number(watch('tax')) === 0 &&
                                        <Form.Control type="text"
                                                      as="textarea" rows={3}
                                                      {...register('textRabat')}
                                                      className="w-100 my-1"
                                                      placeholder="Dodatni komentar (Nije obavezno)"/>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <ModalBody watch={watch} getValues={getValues} handleClose={handleClose}/>
            </Modal>
        </>
    );
};

export default InvoiceForm;
