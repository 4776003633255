import React from 'react';
import {Button} from 'react-bootstrap';
import {FieldErrors, UseFormRegister} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {IInvoiceData} from '../app/form/InvoiceForm';


interface IProps {
    register: UseFormRegister<IInvoiceData>;
    errors: FieldErrors<IInvoiceData>
}

const StickyCard: React.FC<IProps> = ({register, errors}) => {
    const {t} = useTranslation();

    return (
        <div className="sticky-top pt-md-4">
            <div className="border-bottom ">
                <Button variant="primary" name="global-form" className="w-100" type="submit">
                    {t('general.previewInvoice')}
                </Button>
            </div>
            <div className="my-3">
                <label className="fw-bold form-label">{t('general.tax')}:</label>
                <div className="my-1 flex-nowrap input-group">
                    <input placeholder="0.0"
                           {...register('tax')}
                           type="number"
                           className={`bg-white border form-control ${!!errors?.tax && 'is-invalid'}`}/>
                    <span
                        className="bg-light fw-bold text-secondary small input-group-text">%</span>
                </div>
                {!!errors?.tax && <div className="invalid-feedback d-flex">
                    {errors?.tax?.message}
                </div>}
            </div>

            <div className="my-3">
                <label className="fw-bold form-label">{t('general.discount')}:</label>
                <div className="my-1 flex-nowrap input-group">
                    <input placeholder="0.0"
                           type="number"
                           {...register('discount')}
                           className={`bg-white border form-control ${!!errors?.discount && 'is-invalid'}`}
                    />
                    <span
                        className="bg-light fw-bold text-secondary small input-group-text">%</span></div>
                {!!errors?.discount && <div className="invalid-feedback d-flex">
                    {errors?.discount?.message}
                </div>}
            </div>
            <div className="my-3">
                <label className="fw-bold form-label">{t('general.advancePayment')}:</label>
                <div className="my-1 flex-nowrap input-group">
                    <input placeholder="0"
                           {...register('advancePayment')}
                           type="number"
                           className={`bg-white border form-control ${!!errors?.advancePayment && 'is-invalid'}`}/>
                    <span
                        className="bg-light fw-bold text-secondary small input-group-text">€</span>
                </div>
                {!!errors?.advancePayment && <div className="invalid-feedback d-flex">
                    {errors?.advancePayment?.message}
                </div>}
            </div>
        </div>
    );
};

export default StickyCard;
